import React from 'react'
import Layout from '../components/layout'
import Meta from '../components/meta'
import Hero2 from '../components/hero2'
import Featured from '../components/featured'
import News from '../components/home/news'
// import NaturalPool from '../components/home/natural-pool'
import SupportContact from '../components/home/support-contact'
import Relax from '../components/home/relax'
import Difference from '../components/home/difference'
import Technology from '../components/home/technology'
import Robots from '../components/home/robots'
import ClimateCare from '../components/home/climate-care'
import Testimonials from '../components/home/testimonials'
import WhichModel from '../components/home/which-model'
// import Reasons from '../components/home/reasons'

const IndexPage = () => {
	return (
		<Layout>
			<Meta
				title="Maytronics Dolphin | Robotic Swimming Pool Cleaners Australia"
				description="Discover the smartest Maytronics robotic pool cleaners and products yet. Our robotic pool cleaners do the hard work. Book a demo today."
				keywords={[`maytronics`, `maytronics australia`, `home`]}
				lang="en-AU"
			>
				<link
					rel="alternate"
					href="https://www.maytronics.com/es-es/"
					hreflang="es-ES"
				/>
				<link
					rel="alternate"
					href="https://www.maytronics.com/it-it/"
					hreflang="it-IT"
				/>
				<link
					rel="alternate"
					href="https://www.maytronics.com/he-il/"
					hreflang="he-IL"
				/>
				<link
					rel="alternate"
					hreflang="en-US"
					href="https://www.maytronics.com/en-us/"
				/>
				<link
					rel="alternate"
					hreflang="en-CA"
					href="https://www.maytronics.com/en-ca/"
				/>
				<link
					rel="alternate"
					href="https://www.maytronics.com/de-de/"
					hreflang="de-DE"
				/>
				<link
					rel="alternate"
					href="https://www.maytronics.com/de-de/"
					hreflang="de-AT"
				/>
				<link
					rel="alternate"
					href="https://www.robot-dolphin.fr/"
					hreflang="fr-FR"
				/>
				<link
					rel="alternate"
					href="https://www.maytronics.com.au/"
					hreflang="en-AU"
				/>
				<link
					rel="alternate"
					href="https://www.maytronics.com/global/"
					hreflang="x-default"
				/>

				<link
					rel="preload"
					href="https://images.ctfassets.net/ftcnv5up9dxv/xMuFcLZyO2OQnihlJYCQD/5169944aa34214aa74e45b711573f233/BrandImage_HR_Dolphin_M600_CleaningWaterline_01_WithLogo.jpg?w=700&q=80&fit=fill&f=left"
					as="image"
				/>
			</Meta>
			<Hero2 locale="en-AU" />
			<Relax locale="en-AU" />
			<Difference locale="en-AU" />
			<Technology locale="en-AU" />
			<Featured locale="en-AU" />
			<Robots locale="en-AU" />
			<ClimateCare locale="en-AU" />
			<WhichModel locale="en-AU" />
			<Testimonials locale="en-AU" />
			{/* <NaturalPool locale="en-AU" /> */}
			{/* <Reasons locale="en-AU" /> */}
			<SupportContact locale="en-AU" />
			<News locale="en-AU" />
		</Layout>
	)
}

export default IndexPage
